<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">
            Gift Card Details for
            {{ route.query.uid ?? route.params.giftCardId }}
          </h2>
        </div>

        <div class="btn-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click="RefreshData"
          >
            Reload
          </button>
        </div>
      </div>
      <div v-if="data" class="row">
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h4 class="text-gray">Name</h4>
              <h5 class="fw-bolder">{{ data.name }}</h5>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Unique ID</h4>
                <h6>{{ data.uid }}</h6>
              </div>
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Main ID</h4>
                <h6>{{ data.id }}</h6>
              </div>
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Image</h4>
                <img
                  :src="data.image"
                  height="30"
                  width="60"
                  alt="Card Image"
                />
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Created At</h4>
                <h6>
                  {{ data.created_at }}
                </h6>
              </div>
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Last Updated</h4>
                <h6>{{ data.updated_at }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body border-light shadow-sm">
        <form @submit.prevent="UpdateData">
          <div class="">
            <label for="tac" class="form-label">Terms and Condition</label>
            <textarea
              class="form-control"
              id="tac"
              rows="6"
              v-model="tac"
              required
            ></textarea>
          </div>
          <div class="mt-3 text-center">
            <button type="submit" class="btn btn-lg btn-outline-primary px-5">
              Save
            </button>
          </div>
        </form>
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet, apiPut } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";

export default defineComponent({
  name: "AvailableGiftCards",
  components: {
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const tac = ref("");

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/${route.params.giftCardId}`
        );
        data.value = response.data.data.giftCard;
        tac.value = response.data.data.giftCard.tac;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const UpdateData = async () => {
      loading.value = true;
      try {
        await apiPut(`/admin/cards/${route.params.giftCardId}`, {
          tac: tac.value,
        });
        Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      UpdateData,
      route,
      data,
      loading,
      tac,
    };
  },
});
</script>

<style scoped></style>
